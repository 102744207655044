export const characteristics = [
    {id: 1, chars: [
        {title: 'Speed', value: 120},
        {title: 'Speed', value: 120},
        {title: 'Speed', value: 120},
        {title: 'Speed', value: 120},
        {title: 'Speed', value: 120},
        {title: 'Speed', value: 120},
        {title: 'Speed', value: 120},
        {title: 'Speed', value: 120},
    ]},
    {id: 2, chars: [
        {title: 'HP', value: 120},
        {title: 'HP', value: 120},
        {title: 'HP', value: 120},
        {title: 'HP', value: 120},
        {title: 'HP', value: 120},
        {title: 'HP', value: 120},
        {title: 'HP', value: 120},
        {title: 'HP', value: 120},
    ]},
];

export const cards = [
    {id: 1, img: require('../../../../assets/images/placeholder.png')},
    {id: 2, img: require('../../../../assets/images/placeholder.png')},
    {id: 3, img: require('../../../../assets/images/placeholder.png')},
    {id: 4, img: require('../../../../assets/images/placeholder.png')},
    {id: 5, img: require('../../../../assets/images/placeholder.png')},
    {id: 6, img: require('../../../../assets/images/placeholder.png')},
    {id: 7, img: require('../../../../assets/images/placeholder.png')},
    {id: 8, img: require('../../../../assets/images/placeholder.png')},
    {id: 9, img: require('../../../../assets/images/placeholder.png')},
    {id: 10, img: require('../../../../assets/images/placeholder.png')},
];