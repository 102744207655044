import React from "react";
import { PopupButton } from "../../components/PopupButton";

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<
  React.PropsWithChildren<{}>,
  ErrorBoundaryState
> {
  constructor(props: React.PropsWithChildren<{}>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    // Оновлюємо стан, щоб показати запасний UI
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // Ви можете також логувати помилки до своєї системи логування
    console.error("Помилка в ErrorBoundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Відображаємо запасний UI
      return (
        <div className="absolute w-full h-full flex flex-col gap-2 justify-center items-center text-white text-5xl bg-[#1f1c1a] ">
          <img
            src={require("../../assets/images/bugImages/smthWentWrong.png")}
          />
          <div className="pb-4">
            <div className="text-center text-[#ffefcb] text-2xl font-black  leading-normal">
              Something went wrong
            </div>
            <div className="w-60 text-center text-[#ffefcb] text-base font-semibold leading-none">
              Please reload the app and this should fix the problem
            </div>
          </div>

          <PopupButton
            type={"green"}
            onClick={() => {
              window.location.reload();
            }}
          >
            <div className="text-center text-[#ffefcb] text-lg font-black  leading-[18px]">
              Reload App
            </div>
          </PopupButton>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
