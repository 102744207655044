

export const TitleField = (props: {title?: string, fontSize?: string}) => {
    return (
        <div className="items-center justify-center flex">
            <svg width="190" height="42" viewBox="0 0 190 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M173.851 1.25H16.149C14.5836 1.25 13.1188 2.0212 12.2329 3.31171L1.93574 18.3117C0.823828 19.9314 0.823827 22.0686 1.93574 23.6883L12.2329 38.6883C13.1188 39.9788 14.5836 40.75 16.149 40.75H173.851C175.416 40.75 176.881 39.9788 177.767 38.6883L188.064 23.6883C189.176 22.0686 189.176 19.9314 188.064 18.3117L177.767 3.31171C176.881 2.0212 175.416 1.25 173.851 1.25Z" fill="#474029" stroke="url(#paint0_linear_1190_20714)" strokeWidth="1.5"/>
                <mask id="mask0_1190_20714" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="1" y="2" width="188" height="38">
                    <path d="M12.8512 3.73618C13.5972 2.64943 14.8308 2 16.149 2H173.851C175.169 2 176.403 2.64943 177.149 3.73618L187.446 18.7362C188.382 20.1002 188.382 21.8998 187.446 23.2638L177.149 38.2638C176.403 39.3506 175.169 40 173.851 40H16.149C14.8308 40 13.5972 39.3506 12.8512 38.2638L2.55406 23.2638C1.61772 21.8998 1.61772 20.1002 2.55406 18.7362L12.8512 3.73618Z" fill="#453221"/>
                </mask>
                <g mask="url(#mask0_1190_20714)">
                    <path fillRule="evenodd" clipRule="evenodd" d="M1 2C9.93305 2 18.46 2 27.3931 2C36.3261 2 44.8531 2 53.7862 2C55.0043 2.64641 57.4406 3.29282 58.2527 3.29282C58.6588 3.29282 60.689 2.64641 61.9071 2C67.5918 2 72.8704 2 78.5551 2C84.2397 2 89.5184 2 95.203 2C96.0151 3.29282 96.8272 3.93923 96.8272 3.93923C97.2333 3.93923 97.6393 3.29282 98.4514 2C107.791 2 116.724 2 126.063 2C135.402 2 144.335 2 153.674 2C155.298 2.43094 157.734 3.07735 158.14 3.07735C158.952 3.07735 160.983 2.43094 162.201 2C166.667 2 171.134 2 175.6 2C180.067 2 184.533 2 189 2C189 4.58564 189 7.17127 189 9.75691C189 12.3425 189 14.9282 189 17.5138C187.376 17.9448 186.158 18.3757 186.158 18.5912C186.158 18.8066 187.782 19.2376 189 19.6685C189 21.3923 189 23.3315 189 25.0552C189 26.779 189 28.7182 189 30.442C187.782 31.0884 186.97 31.7348 186.97 31.9503C186.97 32.1657 188.188 32.8122 189 33.2431C189 34.5359 189 35.8287 189 37.1215C189 38.4144 189 39.7072 189 41C180.067 41 170.728 41 161.795 41C152.862 41 143.523 41 134.59 41C133.778 40.5691 132.559 39.9227 131.747 39.7072C131.341 39.7072 126.875 40.5691 124.844 41C117.536 41 110.633 41 103.324 41C96.0151 41 89.1123 41 81.8035 41C80.5853 39.7072 79.7732 38.6298 79.3672 38.4144C78.9611 38.4144 78.149 39.2762 76.5248 41C63.9374 41 51.3499 41 39.1685 41C26.987 41 13.5875 40.7845 1 40.7845C1 35.8287 1 30.8729 1 25.9171C1 20.9613 1 16.0055 1 11.0497C3.03024 10.6188 5.06048 9.97238 5.06048 9.75691C5.06048 9.54144 3.03024 9.1105 1 8.46409C1 7.38674 1 6.30939 1 5.23204C1 4.1547 1 3.07735 1 2Z" fill="#C3B996"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M171.572 17.8567C174.017 17.8567 176 15.874 176 13.4283C176 10.9826 174.017 9 171.572 9C169.126 9 167.143 10.9826 167.143 13.4283C167.143 15.874 169.126 17.8567 171.572 17.8567ZM8.1056 33.9723C10.9253 33.9723 13.2112 31.6864 13.2112 28.8667C13.2112 26.0469 10.9253 23.7611 8.1056 23.7611C5.28586 23.7611 3 26.0469 3 28.8667C3 31.6864 5.28586 33.9723 8.1056 33.9723ZM155.925 15.7901C155.925 16.2792 155.528 16.6758 155.039 16.6758C154.55 16.6758 154.154 16.2792 154.154 15.7901C154.154 15.301 154.55 14.9044 155.039 14.9044C155.528 14.9044 155.925 15.301 155.925 15.7901ZM161.534 21.9898C162.675 21.9898 163.601 21.0645 163.601 19.9232C163.601 18.7819 162.675 17.8567 161.534 17.8567C160.393 17.8567 159.468 18.7819 159.468 19.9232C159.468 21.0645 160.393 21.9898 161.534 21.9898Z" fill="#B6AE8A"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M29.3962 26.9911C29.3962 26.4117 29.0876 25.8578 28.6289 25.5038C27.7647 24.8369 27.208 23.7909 27.208 22.6149C27.208 20.6008 28.8408 18.968 30.8549 18.968C32.594 18.968 34.0488 20.1854 34.4136 21.8145C34.4229 21.8558 34.4595 21.8855 34.5018 21.8855V21.8855C37.3216 21.8855 39.6074 24.1714 39.6074 26.9911C39.6074 29.8108 37.3216 32.0967 34.5018 32.0967C31.6821 32.0967 29.3962 29.8108 29.3962 26.9911V26.9911Z" fill="#B6AE8A"/>
                    <ellipse cx="89.5" cy="21.3391" rx="0.5" ry="1" fill="#B6AE8A"/>
                    <circle cx="90" cy="21.3391" r="2" fill="#B6AE8A"/>
                </g>
                <defs>
                    <linearGradient id="paint0_linear_1190_20714" x1="95" y1="2" x2="95" y2="40" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#B48436"/>
                        <stop offset="0.5" stopColor="#5D3E0F"/>
                        <stop offset="1" stopColor="#B48436"/>
                    </linearGradient>
                </defs>
            </svg>
            <p style={{ fontSize: props.fontSize || '20px' }}
             className="absolute font-[800]">{props.title}</p>
        </div>
    )
}
