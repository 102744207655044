
const TutorialContainer = {
  width: window.innerWidth,
  height: window.innerHeight,
  backgroundColor: 'black',
}

const Counter = {
  width: '15%',
  height: ''
}

export const tutorialStyles = {
TutorialContainer: TutorialContainer,
}
