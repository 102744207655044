import { useState, useEffect } from "react";
import { Background } from "../../layout/components/Background";

export const NotFound = () => {
  return (
    <>
      <Background>NotFound</Background>
    </>
  );
};
