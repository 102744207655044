import { useTranslation } from "react-i18next";
import { StatsBlock } from "./components";
import { useSelector } from "react-redux";
import { RootState } from "../../app/store";
import { Resources } from "../../enums/resources";

export const Balance = () => {
  const { t } = useTranslation();

  const resources = useSelector(
    (state: RootState) => state.resources.resources
  );
  
  return (

    <div>
     <div className="pt-6 text-center text-white text-2xl font-normal leading-normal">
        {t("profile.balance")}
      </div>
      <div className="gap-4 flex flex-col mt-2">
        {/* <StatsBlock textKey="profile.kitsune" value="120K" /> */}
        <StatsBlock textKey="profile.currentBalance" value={`${resources[Resources.kitsu]?.value}`} />
      </div>
    </div>
  );
};
