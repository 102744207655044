import { PopupButton } from "../../../PopupButton";

export const BuilderUnavailableCard = ({ config }: any) => (
  <div className="flex justify-center items-center bg-[#473c37] w-full rounded shadow-inner border border-[#18191a] p-2 gap-2">
    <div className="relative min-w-14 min-h-14 justify-center rounded-full items-center inline-flex border border-black bg-[#5c5040]">
      <div className="w-11 h-11 bg-[#312d26] rounded-full border border-black justify-center">
        <img
          className="w-full h-full rounded-full"
          src={require(`../../../../assets/images/${config.image}`)}
          alt="Builder Icon"
        />
      </div>
      <div className="absolute -top-2 -right-2">
        <div className="relative w-full h-full">
          <img
            className="w-[30px] h-[26px]"
            src={require("../../../../assets/images/dialog-cloud.png")}
            alt="Dialog Cloud"
          />
          <svg
            width="7"
            height="9"
            viewBox="0 0 7 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute top-[11px] left-1"
          >
            <path
              d="M6.15375 0.904398C6.34262 0.893942 6.45803 0.89917 6.5 0.920082C6.5 1.3174 6.48951 1.68335 6.46852 2.01794C6.44754 2.34207 6.45803 2.67665 6.5 3.02169C5.96489 3.59676 5.38257 4.17705 4.75303 4.76257C4.12349 5.33764 3.54116 5.92316 3.00605 6.51914C3.53067 6.47732 4.08676 6.47209 4.67434 6.50346C5.2724 6.53483 5.85472 6.50869 6.42131 6.42504C6.42131 6.75963 6.42655 7.11512 6.43705 7.49153C6.44754 7.86794 6.45279 8.23912 6.45279 8.60507C5.97014 8.61552 5.45601 8.62598 4.91041 8.63644C4.3753 8.64689 3.8297 8.65735 3.27361 8.6678C2.71751 8.67826 2.16667 8.68871 1.62107 8.69917C1.07546 8.69917 0.550848 8.69917 0.0472155 8.69917C0.0577078 8.39595 0.0577078 8.15024 0.0472155 7.96204C0.0472155 7.77383 0.0419693 7.61177 0.031477 7.47585C0.0209847 7.33992 0.0104923 7.21445 0 7.09944C0 6.98442 0.00524617 6.8485 0.0157385 6.69166C0.267555 6.36753 0.545601 6.05386 0.849879 5.75064C1.15416 5.43697 1.46893 5.13375 1.79419 4.84099C2.11945 4.53778 2.43947 4.23979 2.75424 3.94702C3.06901 3.64381 3.35755 3.33536 3.61986 3.02169C3.34705 3.03215 3.05327 3.0426 2.7385 3.05306C2.42373 3.06351 2.10896 3.0792 1.79419 3.10011C1.47942 3.12102 1.17514 3.14716 0.881356 3.17853C0.598063 3.20989 0.341001 3.24126 0.110169 3.27263C0.0891848 2.99032 0.0786925 2.74984 0.0786925 2.55118C0.0786925 2.35252 0.0786925 2.17477 0.0786925 2.01794C0.0891848 1.85064 0.0996772 1.68335 0.110169 1.51606C0.120662 1.34877 0.120662 1.15011 0.110169 0.920082C0.960048 0.920082 1.75747 0.920082 2.50242 0.920082C3.25787 0.909626 3.91889 0.904398 4.48547 0.904398C4.56941 0.925309 4.71106 0.935765 4.91041 0.935765C5.12026 0.925309 5.33535 0.920082 5.55569 0.920082C5.77603 0.909626 5.97538 0.904398 6.15375 0.904398Z"
              fill="#19191B"
            />
          </svg>
          <svg
            width="7"
            height="9"
            viewBox="0 0 7 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute top-[7px] left-3"
          >
            <path
              d="M6.15375 0.904398C6.34262 0.893942 6.45803 0.89917 6.5 0.920082C6.5 1.3174 6.48951 1.68335 6.46852 2.01794C6.44754 2.34207 6.45803 2.67665 6.5 3.02169C5.96489 3.59676 5.38257 4.17705 4.75303 4.76257C4.12349 5.33764 3.54116 5.92316 3.00605 6.51914C3.53067 6.47732 4.08676 6.47209 4.67434 6.50346C5.2724 6.53483 5.85472 6.50869 6.42131 6.42504C6.42131 6.75963 6.42655 7.11512 6.43705 7.49153C6.44754 7.86794 6.45279 8.23912 6.45279 8.60507C5.97014 8.61552 5.45601 8.62598 4.91041 8.63644C4.3753 8.64689 3.8297 8.65735 3.27361 8.6678C2.71751 8.67826 2.16667 8.68871 1.62107 8.69917C1.07546 8.69917 0.550848 8.69917 0.0472155 8.69917C0.0577078 8.39595 0.0577078 8.15024 0.0472155 7.96204C0.0472155 7.77383 0.0419693 7.61177 0.031477 7.47585C0.0209847 7.33992 0.0104923 7.21445 0 7.09944C0 6.98442 0.00524617 6.8485 0.0157385 6.69166C0.267555 6.36753 0.545601 6.05386 0.849879 5.75064C1.15416 5.43697 1.46893 5.13375 1.79419 4.84099C2.11945 4.53778 2.43947 4.23979 2.75424 3.94702C3.06901 3.64381 3.35755 3.33536 3.61986 3.02169C3.34705 3.03215 3.05327 3.0426 2.7385 3.05306C2.42373 3.06351 2.10896 3.0792 1.79419 3.10011C1.47942 3.12102 1.17514 3.14716 0.881356 3.17853C0.598063 3.20989 0.341001 3.24126 0.110169 3.27263C0.0891848 2.99032 0.0786925 2.74984 0.0786925 2.55118C0.0786925 2.35252 0.0786925 2.17477 0.0786925 2.01794C0.0891848 1.85064 0.0996772 1.68335 0.110169 1.51606C0.120662 1.34877 0.120662 1.15011 0.110169 0.920082C0.960048 0.920082 1.75747 0.920082 2.50242 0.920082C3.25787 0.909626 3.91889 0.904398 4.48547 0.904398C4.56941 0.925309 4.71106 0.935765 4.91041 0.935765C5.12026 0.925309 5.33535 0.920082 5.55569 0.920082C5.77603 0.909626 5.97538 0.904398 6.15375 0.904398Z"
              fill="#19191B"
            />
          </svg>
          <svg
            width="7"
            height="9"
            viewBox="0 0 7 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="absolute top-[3px] left-5"
          >
            <path
              d="M6.15375 0.904398C6.34262 0.893942 6.45803 0.89917 6.5 0.920082C6.5 1.3174 6.48951 1.68335 6.46852 2.01794C6.44754 2.34207 6.45803 2.67665 6.5 3.02169C5.96489 3.59676 5.38257 4.17705 4.75303 4.76257C4.12349 5.33764 3.54116 5.92316 3.00605 6.51914C3.53067 6.47732 4.08676 6.47209 4.67434 6.50346C5.2724 6.53483 5.85472 6.50869 6.42131 6.42504C6.42131 6.75963 6.42655 7.11512 6.43705 7.49153C6.44754 7.86794 6.45279 8.23912 6.45279 8.60507C5.97014 8.61552 5.45601 8.62598 4.91041 8.63644C4.3753 8.64689 3.8297 8.65735 3.27361 8.6678C2.71751 8.67826 2.16667 8.68871 1.62107 8.69917C1.07546 8.69917 0.550848 8.69917 0.0472155 8.69917C0.0577078 8.39595 0.0577078 8.15024 0.0472155 7.96204C0.0472155 7.77383 0.0419693 7.61177 0.031477 7.47585C0.0209847 7.33992 0.0104923 7.21445 0 7.09944C0 6.98442 0.00524617 6.8485 0.0157385 6.69166C0.267555 6.36753 0.545601 6.05386 0.849879 5.75064C1.15416 5.43697 1.46893 5.13375 1.79419 4.84099C2.11945 4.53778 2.43947 4.23979 2.75424 3.94702C3.06901 3.64381 3.35755 3.33536 3.61986 3.02169C3.34705 3.03215 3.05327 3.0426 2.7385 3.05306C2.42373 3.06351 2.10896 3.0792 1.79419 3.10011C1.47942 3.12102 1.17514 3.14716 0.881356 3.17853C0.598063 3.20989 0.341001 3.24126 0.110169 3.27263C0.0891848 2.99032 0.0786925 2.74984 0.0786925 2.55118C0.0786925 2.35252 0.0786925 2.17477 0.0786925 2.01794C0.0891848 1.85064 0.0996772 1.68335 0.110169 1.51606C0.120662 1.34877 0.120662 1.15011 0.110169 0.920082C0.960048 0.920082 1.75747 0.920082 2.50242 0.920082C3.25787 0.909626 3.91889 0.904398 4.48547 0.904398C4.56941 0.925309 4.71106 0.935765 4.91041 0.935765C5.12026 0.925309 5.33535 0.920082 5.55569 0.920082C5.77603 0.909626 5.97538 0.904398 6.15375 0.904398Z"
              fill="#19191B"
            />
          </svg>
        </div>
      </div>
    </div>
    <div className="w-[132px] text-white text-sm font-normal leading-[14px]">
      {config.message}
    </div>
    <div className="flex flex-col justify-center items-center gap-1 ml-auto">
      <PopupButton height="40px" type={config.button.type}>
        <div
          className={`text-center text-white text-[${
            config.button.size || "13px"
          }] font-normal leading-[${
            config.button.size || "13px"
          }]`}
        >
          {config.button.text}
        </div>
      </PopupButton>
    </div>
  </div>
);
